import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
// import Navbar from '../components/Navbar';
import Home from '../pages/home';

class Router extends Component{
   render(){
      return(
         <Switch>
            <Route exact path="/">
               {/* <Navbar /> */}
               <Home />
            </Route>
         </Switch>
      )
   }
}
export default Router;