import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import '../pages/css/home.css';

//Icon Fonts
import 'boxicons';

import Typed from 'typed.js';

class home extends Component {
   componentDidMount() {
      // You can pass other options here, such as typing speed, back speed, etc.
      const options = {
         strings: ['Graphic Designer', 'UI/UX Designer', 'Frontend Web Developer'],
         loop: true,
         typeSpeed: 100,
         backSpeed: 50,
         backDelay: 2000
      };
      // this.el refers to the <span> in the render() method
      this.typed = new Typed(this.tulis, options);
   }

   componentWillUnmount() {
      // Make sure to destroy Typed instance on unmounting
      // to prevent memory leaks
      this.typed.destroy();
   }

   render() {
      return (
         <div>
            {/* <!-- ======= Hero Section ======= --> */}
            <section id="hero" className="d-flex flex-column justify-content-center">
               <div className="container" data-aos="zoom-in" data-aos-delay="100">
                  <h1>Bagas Nur Prasetyo</h1>
                  <p>I'm a <span
                     style={{ whiteSpace: 'pre' }}
                     ref={(typed) => { this.tulis = typed; }}
                  /></p>
                  <div className="social-links">
                     <a target="blank" href="mailto:bagasnur00@mail.ugm.ac.id" title="Email"><i className='bx bx-mail-send'></i></a>
                     <a target="blank" href="https://linkedin.com/in/bagasnur" title="LinkedIn"><i className="bx bxl-linkedin"></i></a>
                     <a target="blank" href="https://instagram.com/bagasnur.id" title="Instagram"><i className="bx bxl-instagram"></i></a>
                     <a target="blank" href="https://behance.net/bagasnur" title="Behance"><i className='bx bxl-behance'></i></a>
                     <a target="blank" href="https://github.com/bagasnur" title="GitHub"><i className='bx bxl-github'></i></a>
                  </div>
               </div>
            </section>
            {/* <!-- End Hero --> */}
         </div>
      )
   }
}
export default home;